<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-row class="match-height">
        <v-col
          cols="12"
        >
          <label>Duration</label>
          <div class="demo-space-x d-flex flex-wrap">
            <year-range-picker
              ref="picker"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></year-range-picker>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <label>Fixed Holiday</label>
          <div
            v-for="fixedHoliday in fixedHolidayData"
            :key="fixedHoliday.id"
            style="margin-top: -16px;"
          >
            <v-checkbox
              v-model="fixedHolidaySelectedValues"
              :value="fixedHoliday.id"
              :label="fixedHoliday.name"
              :rules="validateCheckbox"
              hide-details
              @click="fixedHolidaySelect"
            />
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <label>Special Day</label>
          <div
            v-for="specialDay in specialDayData"
            :key="specialDay.id"
            style="margin-top: -16px;"
          >
            <v-checkbox
              v-model="specialDaySelectedValues"
              :value="specialDay.id"
              :label="specialDay.name"
              :rules="validateCheckbox"
              hide-details
              @click="specialDaySelect"
            />
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <label></label>
          <v-checkbox
            v-model="isAllFixedSelected"
            :label="`All Fixed Holidays`"
            hide-details
            @click="fixedAllSelects"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <label></label>
          <v-checkbox
            v-model="isAllSpecialSelected"
            :label="`All Special Days`"
            hide-details
            @click="specialAllSelects"
          />
        </v-col>

        <v-col cols="12">
          <v-btn
            style="text-transform: none"
            color="primary"
            @click="search"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div
      v-if="!isEmpty(userData)"
      id="chart"
    >
      <br>
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '400px'}"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import YearRangePicker from '@/views/forms/form-elements/datepicker/YearRangePicker.vue'
import entranceSearch3StoreModule from './entranceSearch3StoreModule'
import store from '@/store'
import 'echarts/lib/chart/line'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import Loading from '@/views/components/load/Loading.vue'

const fixedHolidaySelectedValues = ref([])
const specialDaySelectedValues = ref([])
const USER_APP_STORE_MODULE_NAME = 'app-entranceSearch3'
const userData = ref([])
const echarts = require('echarts')

export default {
  components: {
    YearRangePicker,
    Loading,
  },
  computed: {
    validateCheckbox() {
      const selectedValues = fixedHolidaySelectedValues.value.concat(specialDaySelectedValues.value)

      return [selectedValues.length > 0 || 'At least one item should be selected']
    },
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
      }, 500)
    },
  },
  mounted() {
    this.isAllFixedSelected = true
    this.fixedAllSelects()
    this.isAllSpecialSelected = true
    this.specialAllSelects()
  },
  methods: {
    fixedAllSelects() {
      if (this.isAllFixedSelected) {
        this.fixedHolidayData.forEach(element => {
          fixedHolidaySelectedValues.value.push(element.id)
        })
      } else {
        fixedHolidaySelectedValues.value.splice(0)
      }
    },
    specialAllSelects() {
      if (this.isAllSpecialSelected) {
        this.specialDayData.forEach(element => {
          specialDaySelectedValues.value.push(element.id)
        })
      } else {
        specialDaySelectedValues.value.splice(0)
      }
    },
    fixedHolidaySelect() {
      if (fixedHolidaySelectedValues.value.length !== this.fixedHolidayData.length) {
        this.isAllFixedSelected = false
      } else {
        this.isAllFixedSelected = true
      }
    },
    specialDaySelect() {
      if (specialDaySelectedValues.value.length !== this.specialDayData.length) {
        this.isAllSpecialSelected = false
      } else {
        this.isAllSpecialSelected = true
      }
    },

    search() {
      const isFormValid = this.$refs.form.validate()

      if (!isFormValid) return

      this.loading = true
      const siteId = String(getSessionData('data').siteId)

      const startYear = this.$refs.picker.dateRange[0].getFullYear()
      const endYear = this.$refs.picker.dateRange[1].getFullYear()

      let timeStr

      const selectedValues = fixedHolidaySelectedValues.value.concat(specialDaySelectedValues.value)

      const lenElement = selectedValues.length
      for (let i = 0; i < lenElement; i += 1) {
        if (i === 0) {
          timeStr = `${selectedValues[i]}`
        } else {
          timeStr = `${timeStr},${selectedValues[i]}`
        }
      }
      console.log(timeStr)
      store
        .dispatch('app-entranceSearch3/fetchData', {
          params: {
            siteId,
            startYear,
            endYear,
            timeStr,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]

        const newData = {
          name: element.name,
          type: 'bar',
          label: {
            normal: {
              show: true,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: '5%',
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          top: '5%',
          left: '80%',
          data: val.legendData,
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.xAxisData,
        },
        yAxis: [
          {
            type: 'value',
          },
        ],
        color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
        series: newSeriesData,
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, entranceSearch3StoreModule)
    }

    const specialDayData = ref([])
    const fixedHolidayData = ref([])

    fixedHolidaySelectedValues.value.splice(0)
    specialDaySelectedValues.value.splice(0)
    onUnmounted(() => {
      specialDayData.value.splice(0)
      fixedHolidayData.value.splice(0)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const siteId = String(getSessionData('data').siteId)
    if (siteId === '3' || siteId === '8' || siteId === '11' || siteId === '12' || siteId === '15' || siteId === '16') {
      specialDayData.value = [
        { id: '02-14', name: '14/Feb Valentine' },
        { id: '03-08', name: '8/Mar International Women’s Day' },
        { id: '06-01', name: '1/Jun International Children’s Day' },
        { id: '10-20', name: '20/Oct Vietnamese Women’s Day' },
        { id: '11-20', name: "20/Nov Teacher's Day" },
        { id: '12-24', name: "24/Dec X'mas Eve" },
        { id: '12-25', name: "25/Dec X'mas" },
        { id: '12-31', name: '31/Dec New Year Eve' },
      ]

      fixedHolidayData.value = [
        { id: '01-01', name: "1/Jan New Year's Day" },
        { id: '04-30', name: '30/Apr Reunification Day' },
        { id: '05-01', name: "1/May Workers' Day" },
        { id: '09-02', name: '2/Sep National Day' },
      ]
    } else if (siteId === '2') {
      specialDayData.value = [
        { id: '02-14', name: '14/Feb Valentine Day' },
        { id: '03-08', name: '8/Mar International Women’s Day' },
        { id: '06-01', name: '1/Jun International Children’s Day' },
        { id: '10-31', name: '31/Oct Halloween' },
        { id: '12-24', name: "24/Dec X'mas Eve" },
        { id: '12-25', name: "25/Dec X'mas" },
        { id: '12-31', name: '31/Dec New Year Eve' },
      ]

      fixedHolidayData.value = [
        { id: '01-01', name: "1/Jan New Year's Day" },
        { id: '01-07', name: '7/Jan Victory Day' },
        { id: '04-14', name: '14/Apr Khmer New Year' },
        { id: '04-15', name: '15/Apr Khmer New Year' },
        { id: '04-16', name: '16/Apr Khmer New Year' },
        { id: '05-01', name: '1/May International Labour Day' },
        { id: '05-14', name: "14/May King Norodom Sihamoni's Birthday" },
        { id: '06-18', name: "18/Jun Queen Mother's Birthday" },
        { id: '09-24', name: '24/Sep Constitution Day' },
        { id: '10-15', name: '15/Oct Commemoration Day of Former King Norodom Sihanouk' },
        { id: '10-29', name: '29/Oct King Norodom Sihamoni’s Coronation Day' },
        { id: '11-09', name: '9/Nov National Independence Day' },
      ]
    }

    return {
      isEmpty,
      userData,
      specialDaySelectedValues,
      fixedHolidaySelectedValues,
      specialDayData,
      fixedHolidayData,
      loading: false,
      isAllFixedSelected: false,
      isAllSpecialSelected: false,
    }
  },
}
</script>

<style lang="scss">
.input-class {
  div > input {
    border: 1px ridge rgba(94, 86, 105, 0.68);
    // display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: rgba(94, 86, 105, 0.68);
  }
}
</style>
