<template>
  <div style="min-height:550px">
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <label>Type 1</label>
          <div class="demo-space-x d-flex flex-wrap">
            <div
              v-for="type in types1"
              :key="type.id"
            >
              <v-checkbox
                v-model="type1SelectedValues"
                :value="type.id"
                :label="type.name"
                :rules="type1ValidateCheckbox"
                hide-details
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <label>Type 2</label>
          <div class="demo-space-x d-flex flex-wrap" style="margin-top:-16px;">
            <v-radio-group
              v-model="rowRadio"
              row
              hide-details
            >
              <div
                v-for="type in types2"
                :key="type.id"
              >
                <v-radio
                  :value="type.id"
                  :label="type.name"
                />
              </div>
            </v-radio-group>
          </div>
        </v-col>

        <v-col
          cols="12"
        >
          <label>Duration</label>
          <div>
            <date-range-picker
              ref="picker"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></date-range-picker>
          </div>
        </v-col>

        <v-col
          cols="12"
        >
          <label>Entrance</label>
          <div
            v-for="(data, index) in entranceData"
            :key="index"
            class="demo-space-x d-flex flex-wrap"
          >
            <div
              v-for="entrance in data"
              :key="entrance.id"
              style="width:100px;"
            >
              <v-checkbox
                v-model="entranceSelectedValues"
                :value="entrance.id"
                :label="entrance.facilityName"
                :rules="entranceValidateCheckbox"
                hide-details
                @click="entranceSelect"
              >
                <template v-slot:label>
                  <span id="checkboxLabel">{{ entrance.facilityName }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>
          <div class="demo-space-x d-flex flex-wrap">
            <div>
              <v-checkbox
                v-model="isAllSelected"
                :label="`All Ent`"
                hide-details
                @click="allSelects"
              >
                <template v-slot:label>
                  <span id="checkboxLabel">All Ent</span>
                </template>
              </v-checkbox>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-btn
              style="text-transform: none"
              color="primary"
              @click="search"
            >
              Search
            </v-btn>
            <label>&nbsp;&nbsp;&nbsp;Up to 60 data can be displayed</label>
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="!isEmpty(userData)">
      <div id="chart">
        <br>
        <div
          ref="lineChart"
          :style="{ width: '100%', height: '400px'}"
        ></div>
        <div
          class="d-flex align-left flex-wrap"
        >
          <v-spacer></v-spacer>
          <v-switch
            style="width:250px"
            v-model="countDisplayswitch"
            flat
            :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
            hide-details
            @change="countDisplay"
          ></v-switch>
        </div>
      </div>
      <br>
      <v-col
        v-if="!isEmpty(userData.entranceSearch1Data)"
        cols="12"
        md="6"
      >
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Specified Date
                </th>
                <th
                  v-if="userData.searchType === 0"
                  class="text-center"
                >
                  Time
                </th>
                <th class="text-center">
                  Data
                </th>
                <th class="text-center">
                  Last Year Data
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.entranceSearch1Data"
                :key="`${index}${item.time}`"
                :class="getClass(item.dayOfTheWeek)"
              >
                <td class="text-center">
                  {{ item.dayOfTheWeek }}
                </td>
                <td
                  v-if="userData.searchType === 0"
                  class="text-center"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ String(item.count).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                </td>
                <td class="text-right">
                  {{ String(item.lastYear).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                :key="tableFooterData.time"
                class="yellow"
              >
                <td class="text-center"
                >
                  {{ tableFooterData.time }}
                </td>
                 <td
                  v-if="userData.searchType === 0"
                  class="text-center"
                >
                  -
                </td>
                <td
                  class="text-right"
                >
                  {{ String(tableFooterData.column1).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                </td>
                <td
                  class="text-right"
                >
                  {{ String(tableFooterData.column2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <div
        id="mainTable"
        hidden
        data-cols-width="20"
      >
        <table><tr></tr></table>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Specified Date
                </th>
                <th
                  v-if="userData.searchType === 0"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Time
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Data
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Last Year Data
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.entranceSearch1Data"
                :key="`${index}${item.time}`"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.dayOfTheWeek)"
                >
                  {{ item.dayOfTheWeek }}
                </td>
                <td
                  v-if="userData.searchType === 0"
                  data-t="s"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  {{ item.time }}
                </td>
                <td
                  data-t="n"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.dayOfTheWeek)"
                  :data-num-fmt="item.count===0 ?'0':'###,###'"
                >
                  {{ item.count }}
                </td>
                <td
                  data-t="n"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.dayOfTheWeek)"
                  :data-num-fmt="item.lastYear===0 ?'0':'###,###'"
                >
                  {{ item.lastYear }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                :key="tableFooterData.time"
                class="yellow"
              >
                <td
                 data-t="s"
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ tableFooterData.time }}
                </td>
                <td
                 data-t="s"
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  v-if="userData.searchType === 0"
                >
                  -
                </td>
                <td
                  data-t="n"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-num-fmt="###,###"
                >
                  {{ tableFooterData.column1 }}
                </td>
                <td
                  data-t="n"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-num-fmt="###,###"
                >
                  {{ tableFooterData.column2 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </div>
      <v-btn
        v-if="!isEmpty(userData.entranceSearch1Data)"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiDownloadOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import DateRangePicker from '@/views/forms/form-elements/datepicker/DateRangePicker.vue'
import store from '@/store'
import entranceSearch1StoreModule from './entranceSearch1StoreModule'
import { isEmpty, getSessionData, date2String, date2yyyymmddhhmmss } from '@core/utils'
import tableToExcelJs from '@linways/table-to-excel'
import Loading from '@/views/components/load/Loading.vue'
import 'echarts/lib/chart/line'

const userData = ref([])
const currentDate = new Date()
const type1SelectedValues = ref([1, 2, 3])
const entranceSelectedValues = ref([])
const USER_APP_STORE_MODULE_NAME = 'app-entranceSearch1'
const echarts = require('echarts')

export default {
  components: {
    DateRangePicker,
    Loading,
  },
  props: {
    entranceData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type1ValidateCheckbox() {
      return [this.type1SelectedValues.length > 0 || 'At least one item should be selected']
    },
    entranceValidateCheckbox() {
      return [this.entranceSelectedValues.length > 0 || 'At least one item should be selected']
    },
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
      }, 500)
    },
  },
  methods: {
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },
    allSelects() {
      if (this.isAllSelected) {
        Object.values(this.entranceData).forEach(element => {
          element.forEach(ele => {
            entranceSelectedValues.value.push(ele.id)
          })
        })
      } else {
        entranceSelectedValues.value.splice(0)
      }
    },
    entranceSelect() {
      let i = 0
      Object.values(this.entranceData).forEach(element => {
        element.forEach(() => {
          i += 1
        })
      })

      if (entranceSelectedValues.value.length !== i) {
        this.isAllSelected = false
      } else {
        this.isAllSelected = true
      }
    },
    search() {
      const isFormValid = this.$refs.form.validate()

      if (!isFormValid) return

      this.loading = true
      const siteId = String(getSessionData('data').siteId)

      const startDate = this.$refs.picker.dateRange[0]
      const endDate = this.$refs.picker.dateRange[1]
      const searchStartDate = date2String(startDate)
      const searchEndDate = date2String(endDate)

      const timeType = this.rowRadio
      let facilityIds
      let weekDayFlag = 0
      let saturDayFlag = 0
      let sunDayFlag = 0

      if (this.type1SelectedValues.indexOf(1) !== -1) {
        weekDayFlag = 1
      }
      if (this.type1SelectedValues.indexOf(2) !== -1) {
        saturDayFlag = 1
      }
      if (this.type1SelectedValues.indexOf(3) !== -1) {
        sunDayFlag = 1
      }
      const lenElement = entranceSelectedValues.value.length

      for (let i = 0; i < lenElement; i += 1) {
        if (i === 0) {
          facilityIds = `${entranceSelectedValues.value[i]}`
        } else {
          facilityIds = `${facilityIds},${entranceSelectedValues.value[i]}`
        }
      }

      store
        .dispatch('app-entranceSearch1/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
            weekDayFlag,
            saturDayFlag,
            sunDayFlag,
            timeType,
            facilityIds,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          userData.value.labelShow = this.countDisplayswitch
          this.loading = false

          let count = 0
          let lastYear = 0
          userData.value.entranceSearch1Data.forEach(item => {
            count += Number(item.count)
            lastYear += Number(item.lastYear)
          })
          this.tableFooterData.column1 = count
          this.tableFooterData.column2 = lastYear
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]

        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: val.labelShow,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
      if (!isEmpty(val.legendData)) {
        this.$refs.lineChart.style.display = 'block'
      } else {
        this.$refs.lineChart.style.display = 'none'
      }
    },

    /* excelダウンロード */
    generateXLSX: () => {
      tableToExcelJs.convert(document.getElementById('mainTable'), {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },
    getClass(time) {
      let css = ''
      if (this.rowRadio !== 0) {
        if (time.indexOf('Sat') >= 0) {
          css = 'Sat'
        } else if (time.indexOf('Sun') >= 0) {
          css = 'Sun'
        }
      }

      return css
    },
    getColor(time) {
      let color = ''
      if (this.rowRadio !== 0) {
        if (time.indexOf('Sat') >= 0) {
          color = 'ccffff'
        } else if (time.indexOf('Sun') >= 0) {
          color = 'ffccff'
        }
      }

      return color
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, entranceSearch1StoreModule)
    }

    onUnmounted(() => {
      userData.value = []
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    let startDateString = sessionStorage.getItem('startDate')
    let endDateString = sessionStorage.getItem('endDate')

    if (startDateString === '') {
      startDateString = currentDate
      endDateString = currentDate
    }

    const initDateRange = {
      startDate: startDateString,
      endDate: endDateString,
    }

    const types1 = [
      { id: 1, name: 'Weekday' },
      { id: 2, name: 'Saturday' },
      { id: 3, name: 'Sunday' },
    ]
    const types2 = [
      { id: 0, name: 'Hourly' },
      { id: 1, name: 'Daily(Same Date)' },
      { id: 2, name: 'Daily(Same Week)' },
    ]
    const countDisplayswitch = ref(true)

    const tableFooterData = {
      time: 'Total',
      column1: '',
      column2: '',
    }

    Object.values(props.entranceData).forEach(element => {
      element.forEach(ele => {
        entranceSelectedValues.value.push(ele.id)
      })
    })

    return {
      tableFooterData,
      countDisplayswitch,
      userData,
      initDateRange,
      types1,
      type1SelectedValues,
      types2,
      entranceSelectedValues,
      rowRadio: 0,
      isEmpty,
      loading: false,
      icons: {
        mdiDownloadOutline,
      },
      isAllSelected: true,
    }
  },
}
</script>

<style scoped>
.demo-space-x {
  margin-top: -30px;
}
.theme--light.v-data-table td {
  color: #000 !important;
  border: 1px solid rgba(94, 86, 105, 0.14);
}
.theme--light.v-data-table th {
  border: 1px solid rgba(94, 86, 105, 0.14);
}
#checkboxLabel {
  font-size: 14px;
}
.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
</style>
