<template>
  <div>
    <div
      style="display: flex;"
    >
      <v-simple-table
        dense
        style="width:754px"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-center"
                rowspan="2"
              >
                Time
              </th>
              <th
                class="text-center"
                style="background: #ffbbff !important;height:14px"
                colspan="5"
              >
                GF
              </th>
              <th
                class="text-center"
                style="background: #64fcfc !important;height:14px;"
                colspan="6"
              >
                3F
              </th>
              <th
                class="text-center"
                style="background: #ffff88 !important;"
                rowspan="2"
              >
                Total
              </th>
            </tr>
            <tr>
              <th class="text-center">
                Ent A
              </th>
              <th class="text-center">
                Ent B
              </th>
              <th class="text-center">
                Ent B2
              </th>
              <th class="text-center">
                Ent C
              </th>
              <th class="text-center">
                Ent D
              </th>
              <th class="text-center">
                Ent E1
              </th>
              <th class="text-center">
                Ent E2
              </th>
              <th class="text-center">
                Ent G1
              </th>
              <th class="text-center">
                Ent G2
              </th>
              <th class="text-center">
                Ent H1
              </th>
              <th class="text-center">
                Ent H2
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in userData.table2Data"
              :key="item.time"
              :class="getClass(index)"
            >
              <td class="text-center">
                {{ item.time }}
              </td>
              <td class="text-right">
                {{ item.count1 }}
              </td>
              <td class="text-right">
                {{ item.count2 }}
              </td>
              <td class="text-right">
                {{ item.count3 }}
              </td>
              <td class="text-right">
                {{ item.count4 }}
              </td>
              <td class="text-right">
                {{ item.count5 }}
              </td>
              <td class="text-right">
                {{ item.count6 }}
              </td>
              <td class="text-right">
                {{ item.count7 }}
              </td>
              <td class="text-right">
                {{ item.count8 }}
              </td>
              <td class="text-right">
                {{ item.count9 }}
              </td>
              <td class="text-right">
                {{ item.count10 }}
              </td>
              <td class="text-right">
                {{ item.count11 }}
              </td>
              <td class="text-right">
                {{ item.count12 }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-for="item in userData.table2FooterData"
              :key="item.time"
              :class="item.time === 'Total' ? 'yellow': ''"
            >
              <td class="text-center">
                {{ item.time }}
              </td>
              <td class="text-right">
                {{ item.count1 }}
              </td>
              <td class="text-right">
                {{ item.count2 }}
              </td>
              <td class="text-right">
                {{ item.count3 }}
              </td>
              <td class="text-right">
                {{ item.count4 }}
              </td>
              <td class="text-right">
                {{ item.count5 }}
              </td>
              <td class="text-right">
                {{ item.count6 }}
              </td>
              <td class="text-right">
                {{ item.count7 }}
              </td>
              <td class="text-right">
                {{ item.count8 }}
              </td>
              <td class="text-right">
                {{ item.count9 }}
              </td>
              <td class="text-right">
                {{ item.count10 }}
              </td>
              <td class="text-right">
                {{ item.count11 }}
              </td>
              <td class="text-right">
                {{ item.count12 }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
      <br>
      <br>
    </div>
    <!-- excel出力用 -->
    <div
      id="mainTable2"
      hidden
      data-cols-width="20,10,10,10,10,10,10,10,10,10,10,10"
    >
      <table><tr></tr></table>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                data-b-a-s="thin"
                data-a-h="center"
              >
                Time
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent A
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent B
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent B2
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent C
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent D
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent E1
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent E2
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent G1
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent G2
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent H1
              </th>
              <th
                data-b-a-s="thin"
                data-a-h="center"
                class="text-center"
              >
                Ent H2
              </th>
              <th
                data-b-a-s="thin"
                data-fill-color="ffff88"
                data-a-h="center"
                class="text-center"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in userData.table2Data"
              :key="item.time"
              :data-fill-color="getColor(index)"
            >
              <td
                data-b-a-s="thin"
                data-a-h="center"
                :data-fill-color="getColor(index)"
              >
                {{ item.time }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count1).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count2).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count3).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count4).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count5).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count6).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count7).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count8).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count9).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count10).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count11).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                :data-fill-color="getColor(index)"
              >
                {{ String(item.count12).replace(',','').replace(',','') }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-for="(item, index) in userData.table2FooterData"
              :key="item.time"
              :class="item.time === 'Total' ? 'yellow': ''"
            >
              <td
                data-b-a-s="thin"
                data-a-h="center"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                {{ item.time }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count1).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count2).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count2).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count3).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count4).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count4).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count5).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count5).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count6).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count6).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
                class="text-right"
              >
                <p v-if="index == 0">
                  {{ String(item.count7).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count7).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
                class="text-right"
              >
                <p v-if="index == 0">
                  {{ String(item.count8).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count8).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
                class="text-right"
              >
                <p v-if="index == 0">
                  {{ String(item.count9).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count9).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
                class="text-right"
              >
                <p v-if="index == 0">
                  {{ String(item.count10).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count10).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="index == 0 ? (item.count11 === '0'?'0':'###,###'):'0.0%'"
                :data-fill-color="index == 0 ? 'ffff88':''"
                class="text-right"
              >
                <p v-if="index == 0">
                  {{ String(item.count11).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  {{ Number(String(item.count11).replace('%',''))/100 }}
                </p>
              </td>
              <td
                data-b-a-s="thin"
                :data-t="index == 0 ? 'n':'s'"
                :data-num-fmt="index == 0 ? (item.count12 === '0'?'0':'###,###'):''"
                :data-fill-color="index == 0 ? 'ffff88':''"
              >
                <p v-if="index == 0">
                  {{ String(item.count12).replace(',','').replace(',','') }}
                </p>
                <p v-if="index != 0">
                  -
                </p>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
    <v-col>
      <v-btn
        v-if="!loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </v-col>
  </div>
</template>
<script>
import { isEmpty, date2yyyymmddhhmmss } from '@core/utils'
import { mdiDownloadOutline } from '@mdi/js'
import tableToExcelJs from '@linways/table-to-excel'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getClass(index) {
      let css = ''

      if (index % 2 !== 0) {
        css = 'Gray'
      }

      return css
    },

    getColor(index) {
      let color = ''
      if (index % 2 !== 0) {
        color = 'c0c0c0'
      }

      return color
    },

    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable2')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },
  },
  setup() {
    return {
      isEmpty,
      icons: {
        mdiDownloadOutline,
      },
    }
  },
}
</script>

<style scoped>
.tableCss3 th {
  padding: 0px 5px !important;
  /* background: #ccffcc !important; */
}

.v-data-table tbody .Normal {
  background-color: #fff;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}

.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}

.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000 !important;
  padding: 0px 1px !important;
  font-size: 12px !important;
  width: 58px;
}

.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  white-space: nowrap;
  width: 58px;
  height: 14px;
}

.summary-Table.v-data-table th,
td {
  border: none !important;
}

.commentTableCss {
  width: 1190px;
}

.commentTableCss th {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.commentTableCss td {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.commentTableCss tfoot tr {
  background: #ffff88 !important;
}

table tr td.comment div {
  width: 160px;
  height: 100%;
  overflow-y: auto;
}

table tr td.comment {
  padding-left: 1px !important;
  text-align: left !important;
  padding: 0 !important;
  margin: 0 !important;
  white-space: initial !important;
  min-width: initial !important;
}
</style>
