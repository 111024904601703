<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab style="text-transform: none">
        Ent Search1
      </v-tab>
      <v-tab style="text-transform: none">
        Ent Search2
      </v-tab>
      <v-tab style="text-transform: none">
        Ent Search3
      </v-tab>
      <v-tab style="text-transform: none">
        Ent Search4
      </v-tab>
      <!-- <v-tab style="text-transform: none">Corridor Search1</v-tab> -->
      <v-tab style="text-transform: none" v-if="siteId === '3' || siteId === '8' || siteId === '12' || siteId === '15' || siteId === '16'">
        Cor Search
      </v-tab>
    </v-tabs>

    <v-card flat>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-title>
              Entrance Search 1
            </v-card-title>
            <v-card-text>
              <Form-Layout-Entrance-Search1
                v-if="!isEmpty(entranceData)"
                :entrance-data="entranceData"
              ></Form-Layout-Entrance-Search1>
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-title>
              Entrance Search 2
            </v-card-title>
            <v-card-text>
              <Form-Layout-Entrance-Search2
                v-if="!isEmpty(entranceData)"
                :entrance-data="entranceData"
              ></Form-Layout-Entrance-Search2>
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-title>
              Entrance Search 3
            </v-card-title>
            <v-card-text>
              <Form-Layout-Entrance-Search3></Form-Layout-Entrance-Search3>
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-title>
              Entrance Search 4
            </v-card-title>
            <v-card-text>
              <Form-Layout-Entrance-Search4></Form-Layout-Entrance-Search4>
            </v-card-text>
          </v-tab-item>
          <v-tab-item v-if="siteId === '3' || siteId === '8' || siteId === '12' || siteId === '15' || siteId === '16'">
            <v-card-title>
              Corridor Search
            </v-card-title>
            <v-card-text>
              <Form-Layout-Corridor-Search1></Form-Layout-Corridor-Search1>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import FormLayoutEntranceSearch1 from './EntranceSearch1.vue'
import FormLayoutEntranceSearch2 from './EntranceSearch2.vue'
import FormLayoutEntranceSearch3 from './EntranceSearch3.vue'
import FormLayoutEntranceSearch4 from './EntranceSearch4.vue'
import { getSessionData, isEmpty } from '@core/utils'
import store from '@/store'
import searchStoreModule from './searchStoreModule'
import FormLayoutCorridorSearch1 from './CorridorSearch1.vue'

const USER_APP_STORE_MODULE_NAME = 'app-search'

const entranceDataFirstFloor = ref([])
const entranceDataFourFloor = ref([])
const entranceDataBfFloor = ref([])

const entranceData = ref([])

export default {
  components: {
    FormLayoutEntranceSearch1,
    FormLayoutEntranceSearch2,
    FormLayoutEntranceSearch3,
    FormLayoutEntranceSearch4,
    FormLayoutCorridorSearch1,
  },
  created() {
    this.searchOperation()
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  methods: {
    // 検索処理を行う
    searchOperation() {
      const siteId = String(getSessionData('data').siteId)
      store
        .dispatch('app-search/fetchData', {
          params: {
            siteId,
          },
        })
        .then(response => {
          entranceData.value = response.data.result.data.entranceData

          // userData.value = response.data
        })
        .catch(error => {
          // ログイン画面に遷移する
          console.log(error)
          this.loading = false
          this.$router.push({ name: 'error-login' })
        })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, searchStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const tab = ref('')

    return {
      tab,
      entranceDataFirstFloor,
      entranceDataFourFloor,
      entranceDataBfFloor,
      entranceData,
      isEmpty,
      siteId: String(getSessionData('data').siteId),
    }
  },
}
</script>

<style scoped>
table.v-table thead th {
  font-size: 20px !important;
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 1px solid rgba(94, 86, 105, 0.14);
}
.theme--light.v-data-table th {
  border: 1px solid rgba(94, 86, 105, 0.14);
}
</style>
