<template>
  <v-container
    class="floorplan-container pa-0"
  >
    <!-- Image of floor plan -->
    <v-row
      no-gutters
      justify="center"
      class="flex-nowrap"
    >
      <v-col>
        <v-img
          :src="imgSrc"
          :style="{ width: imageWidth, height: imageHeight}"
          style="border: 1px solid;"
        >
          <!-- Block -->
          <v-row
            v-for="blockItem in blockData"
            :key="blockItem.id"
            no-gutters
            justify="center"
            class="floorplan-block-search2 flex-column pl-1 pr-1"
            :style="!isEmpty(blockItem.positionX) ? { left: blockItem.positionX, top: blockItem.positionY } :'display:none'"
          >
            <v-col class="text-center" style="font-size:12px">
              {{ blockItem.title }}
            </v-col>
            <v-col>
              <v-row
                v-for="(blockInfoItem, index) in blockItem.blockInfo"
                :key="index"
                no-gutters
                justify="space-between"
                align="center"
                class="font-weight-thin"
                style="font-size:8px"
              >
                <v-col class="text-left">
                  <template v-if="blockInfoItem.direction === DirectionEnum.Right">
                    <v-icon small>
                      {{ icons.mdiArrowRightBold }}
                    </v-icon>
                    <span class="mr-1">{{ blockInfoItem.direction }}</span>
                  </template>
                  <template v-else>
                    <span>{{ blockInfoItem.direction }}</span>
                    <v-icon
                      small
                      class="mr-1"
                    >
                      {{ blockInfoItem.direction === DirectionEnum.Left ? icons.mdiArrowLeftBold : blockInfoItem.direction === DirectionEnum.Up ? icons.mdiArrowUpBold : icons.mdiArrowDownBold }}
                    </v-icon>
                  </template>
                </v-col>
                <v-col class="text-right">
                  <span>{{ String(blockInfoItem.count).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Arrow -->
          <v-row
            v-for="arrowItem in arrowData"
            :key="arrowItem.id"
            justify="center"
            class="floorplan-arrow"
            :style="{ left: arrowItem.positionX, top: arrowItem.positionY }"
          >
            <v-col class="pa-0">
              <v-icon
                small
                :color="arrowItem.color"
                :style="{ transform: `rotate(${arrowItem.rotate})` }"
              >
                {{ icons.mdiArrowLeftBold }}
              </v-icon>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiArrowLeftBold, mdiArrowRightBold, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js'
import { isEmpty } from '@core/utils'

export default {
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: String,
      required: true,
    },
    imageHeight: {
      type: String,
      required: true,
    },
    arrowData: {
      type: Array,
      default: () => [],
    },
    entranceData: {
      type: Array,
      default: () => [],
    },
    blockData: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const DirectionEnum = {
      Left: 'L',
      Right: 'R',
      Up: 'U',
      Down: 'D',
    }

    return {
      isEmpty,
      DirectionEnum,
      icons: {
        mdiArrowLeftBold,
        mdiArrowRightBold,
        mdiArrowUpBold,
        mdiArrowDownBold,
      },
    }
  },
}
</script>
