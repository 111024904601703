<template>
  <div style="min-height:450px">
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <label>Duration A</label>
          <div>
            <date-range-picker
              ref="picker1"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></date-range-picker>
          </div>
        </v-col>

        <v-col
          cols="12"
        >
          <label>Duration B</label>
          <div>
            <date-range-picker
              ref="picker2"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></date-range-picker>
          </div>
        </v-col>

        <v-col cols="12">
          <v-btn
            style="text-transform: none"
            color="primary"
            @click="search"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div id="exportMapDiv">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <label>{{durationAText}}</label>
          <floorplan
            v-if="!isEmpty(userData.durationA)"
            id="exportMapDiv"
            :img-src="require(`@/assets${userData.durationA.image_src}`)"
            :image-width="userData.durationA.image_witdh"
            :image-height="userData.durationA.image_height"
            :block-data="userData.durationA.blockData"
            :arrow-data="userData.durationA.arrowData"
          >
          </floorplan>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <label>{{durationBText}}</label>
          <floorplan
            v-if="!isEmpty(userData.durationB)"
            id="exportMapDiv"
            :img-src="require(`@/assets${userData.durationB.image_src}`)"
            :image-width="userData.durationB.image_witdh"
            :image-height="userData.durationB.image_height"
            :block-data="userData.durationB.blockData"
            :arrow-data="userData.durationB.arrowData"
          >
          </floorplan>
        </v-col>
      </v-row>
    </div>
    <br>
    <div>
      <v-btn
      icon
      small
        v-if="!isEmpty(userData.durationA)"
        @click="exportMaptoImage"
      >
        <v-icon
          center
        >
          {{ icons.mdiArrowCollapseDown }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DateRangePicker from '@/views/forms/form-elements/datepicker/DateRangePicker.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { isEmpty, getSessionData, date2String, dateFormattedddmmmyyydd } from '@core/utils'
import floorplan from '@/views/components/blueprint/FloorPlan2.vue'
import Loading from '@/views/components/load/Loading.vue'
import { mdiArrowCollapseDown } from '@mdi/js'
import store from '@/store'
import corridorSearch1StoreModule from './corridorSearch1StoreModule'
import html2canvas from 'html2canvas'

const USER_APP_STORE_MODULE_NAME = 'app-corridorSearch'
const userData = ref([])

export default {
  components: {
    DateRangePicker,
    floorplan,
    Loading,
  },
  methods: {
    search() {
      this.loading = true
      const siteId = String(getSessionData('data').siteId)

      const startADate = this.$refs.picker1.dateRange[0]
      const endADate = this.$refs.picker1.dateRange[1]
      const searchAStartDate = date2String(startADate)
      const searchAEndDate = date2String(endADate)

      const startADateString = dateFormattedddmmmyyydd(searchAStartDate)
      const endADateString = dateFormattedddmmmyyydd(searchAEndDate)

      this.durationAText = `Duration A ${startADateString} 〜 ${endADateString}`

      const startBDate = this.$refs.picker2.dateRange[0]
      const endBDate = this.$refs.picker2.dateRange[1]
      const searchBStartDate = date2String(startBDate)
      const searchBEndDate = date2String(endBDate)

      const startBDateString = dateFormattedddmmmyyydd(searchBStartDate)
      const endBDateString = dateFormattedddmmmyyydd(searchBEndDate)
      this.durationBText = `Duration B ${startBDateString} 〜 ${endBDateString}`

      store
        .dispatch('app-corridorSearch/fetchData', {
          params: {
            siteId,
            searchAStartDate,
            searchAEndDate,
            searchBStartDate,
            searchBEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data

          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },

    /* map印刷 */
    exportMaptoImage: () => {
      const currentDate = new Date()
      html2canvas(document.querySelector('#exportMapDiv')).then(canvas => {
        const downloadEle = document.createElement('a')
        downloadEle.href = canvas.toDataURL('image/png')
        downloadEle.download = `CorridorSearch2_${currentDate.toLocaleString()}.jpeg`
        downloadEle.click()
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, corridorSearch1StoreModule)
    }

    onUnmounted(() => {
      userData.value = []
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // const currentDate = new Date()
    // let startDateString = sessionStorage.getItem('startDate')
    // let endDateString = sessionStorage.getItem('endDate')

    // if (startDateString === '') {
    //   startDateString = currentDate
    //   endDateString = currentDate
    // }

    // userData.value = {
    //   durationA: {
    //     image_src: '/images/mollImage/16/corridor.png',
    //     image_witdh: '570px',
    //     image_height: '1188px',
    //     arrowData: [
    //       {
    //         color: '#51fab1',
    //         id: '4514 L←',
    //         positionX: '69.9%',
    //         positionY: '76.5%',
    //         rotate: '135deg',
    //       },
    //       {
    //         color: '#6cf689',
    //         id: '4464 L←',
    //         positionX: '54.3%',
    //         positionY: '7.5%',
    //         rotate: '30deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4472 L←',
    //         positionX: '79.6%',
    //         positionY: '20.9%',
    //         rotate: '55deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4468 L←',
    //         positionX: '77.3%',
    //         positionY: '7.7%',
    //         rotate: '50deg',
    //       },
    //       {
    //         color: '#2be0d5',
    //         id: '4470 L←',
    //         positionX: '70.0%',
    //         positionY: '13%',
    //         rotate: '45deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4510 L←',
    //         positionX: '54.3%',
    //         positionY: '75.3%',
    //         rotate: '30deg',
    //       },
    //       {
    //         color: '#2be0d5',
    //         id: '4462 L←',
    //         positionX: '58.3%',
    //         positionY: '3.2%',
    //         rotate: '10deg',
    //       },
    //       {
    //         color: '#cee532',
    //         id: '4454 L←',
    //         positionX: '25.3%',
    //         positionY: '5.6%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#e6321b',
    //         id: '4458 L←',
    //         positionX: '36.8%',
    //         positionY: '6%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#0099ff',
    //         id: '4484 L←',
    //         positionX: '61.3%',
    //         positionY: '36.4%',
    //         rotate: '15deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4486 L←',
    //         positionX: '56.7%',
    //         positionY: '41.5%',
    //         rotate: '20deg',
    //       },
    //       {
    //         color: '#1d2ce7',
    //         id: '4508 L←',
    //         positionX: '58.5%',
    //         positionY: '70.7%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4518 L←',
    //         positionX: '69%',
    //         positionY: '81.3%',
    //         rotate: '40deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4520 L←',
    //         positionX: '78%',
    //         positionY: '86.3%',
    //         rotate: '80deg',
    //       },
    //       {
    //         color: '#6cf689',
    //         id: '4476 L←',
    //         positionX: '27.9%',
    //         positionY: '39.2%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#2be0d5',
    //         id: '4693 L←',
    //         positionX: '39.0%',
    //         positionY: '39.2%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#0099ff',
    //         id: '4490 L←',
    //         positionX: '80.5%',
    //         positionY: '43.3%',
    //         rotate: '70deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4492 L←',
    //         positionX: '70.7%',
    //         positionY: '46.9%',
    //         rotate: '50deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4496 L←',
    //         positionX: '78.2%',
    //         positionY: '52.9%',
    //         rotate: '70deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4500 L←',
    //         positionX: '23.5%',
    //         positionY: '73.2%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4504 L←',
    //         positionX: '35.5%',
    //         positionY: '73.4%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#1d2ce7',
    //         id: '4701 L←',
    //         positionX: '72.9%',
    //         positionY: '73.8%',
    //         rotate: '135deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4514 →R',
    //         positionX: '67.3%',
    //         positionY: '77.8%',
    //         rotate: '315deg',
    //       },
    //       {
    //         color: '#6cf689',
    //         id: '4464 →R',
    //         positionX: '58.0%',
    //         positionY: '8.5%',
    //         rotate: '210deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4472 →R',
    //         positionX: '81.6%',
    //         positionY: '22.6%',
    //         rotate: '235deg',
    //       },
    //       {
    //         color: '#0099ff',
    //         id: '4468 →R',
    //         positionX: '79.0%',
    //         positionY: '9.2%',
    //         rotate: '230deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4470 →R',
    //         positionX: '73%',
    //         positionY: '14.5%',
    //         rotate: '225deg',
    //       },
    //       {
    //         color: '#95ff7b',
    //         id: '4510 →R',
    //         positionX: '57.8%',
    //         positionY: '76.3%',
    //         rotate: '210deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4462 →R',
    //         positionX: '63.0%',
    //         positionY: '3.5%',
    //         rotate: '190deg',
    //       },
    //       {
    //         color: '#f0a605',
    //         id: '4454 →R',
    //         positionX: '29%',
    //         positionY: '5.6%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#f58c04',
    //         id: '4458 →R',
    //         positionX: '41%',
    //         positionY: '6%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#066ff7',
    //         id: '4484 →R',
    //         positionX: '65.6%',
    //         positionY: '37%',
    //         rotate: '195deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4486 →R',
    //         positionX: '59.8%',
    //         positionY: '42.1%',
    //         rotate: '200deg',
    //       },
    //       {
    //         color: '#0a50ff',
    //         id: '4508 →R',
    //         positionX: '62.9%',
    //         positionY: '70.7%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4518 →R',
    //         positionX: '71.5%',
    //         positionY: '82.4%',
    //         rotate: '220deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4520 →R',
    //         positionX: '78.6%',
    //         positionY: '87.9%',
    //         rotate: '260deg',
    //       },
    //       {
    //         color: '#95ff7b',
    //         id: '4476 →R',
    //         positionX: '31.9%',
    //         positionY: '39.2%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4693 →R',
    //         positionX: '43.6%',
    //         positionY: '39.2%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#066ff7',
    //         id: '4490 →R',
    //         positionX: '81.4%',
    //         positionY: '44.9%',
    //         rotate: '250deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4492 →R',
    //         positionX: '73.4%',
    //         positionY: '48.5%',
    //         rotate: '230deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4496 →R',
    //         positionX: '79.3%',
    //         positionY: '54.5%',
    //         rotate: '250deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4500 →R',
    //         positionX: '27.9%',
    //         positionY: '73.2%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#95ff7b',
    //         id: '4504 →R',
    //         positionX: '39.9%',
    //         positionY: '73.4%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#1d2ce7',
    //         id: '4701 →R',
    //         positionX: '70.5%',
    //         positionY: '74.9%',
    //         rotate: '315deg',
    //       },
    //     ],
    //     blockData: [
    //       {
    //         blockInfo: [
    //           {
    //             count: 0,
    //             direction: 'D',
    //           },
    //           {
    //             count: 0,
    //             direction: 'U',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4701,
    //         positionX: '73%',
    //         positionY: '70%',
    //         title: 'Block FC',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 9915,
    //             direction: 'L',
    //           },
    //           {
    //             count: 9858,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4520,
    //         positionX: '66%',
    //         positionY: '91.5%',
    //         title: 'Block U',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 10280,
    //             direction: 'L',
    //           },
    //           {
    //             count: 12422,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4518,
    //         positionX: '77%',
    //         positionY: '81%',
    //         title: 'Block T',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 12603,
    //             direction: 'D',
    //           },
    //           {
    //             count: 12193,
    //             direction: 'U',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4514,
    //         positionX: '50.6%',
    //         positionY: '85.3%',
    //         title: 'Block S',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 14027,
    //             direction: 'L',
    //           },
    //           {
    //             count: 17996,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4510,
    //         positionX: '40%',
    //         positionY: '78.9%',
    //         title: 'Block R',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 654,
    //             direction: 'L',
    //           },
    //           {
    //             count: 1075,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4508,
    //         positionX: '56.5%',
    //         positionY: '65%',
    //         title: 'Block Q',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 13375,
    //             direction: 'L',
    //           },
    //           {
    //             count: 18251,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4504,
    //         positionX: '33.5%',
    //         positionY: '69.4%',
    //         title: 'Block P',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 10278,
    //             direction: 'L',
    //           },
    //           {
    //             count: 12974,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4500,
    //         positionX: '20.3%',
    //         positionY: '77%',
    //         title: 'Block O',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 7937,
    //             direction: 'L',
    //           },
    //           {
    //             count: 8323,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4496,
    //         positionX: '67.3%',
    //         positionY: '57%',
    //         title: 'Block N',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 7415,
    //             direction: 'L',
    //           },
    //           {
    //             count: 9310,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4492,
    //         positionX: '59.3%',
    //         positionY: '51.3%',
    //         title: 'Block M',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 5651,
    //             direction: 'L',
    //           },
    //           {
    //             count: 4515,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4490,
    //         positionX: '82%',
    //         positionY: '42%',
    //         title: 'Block L',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 9641,
    //             direction: 'L',
    //           },
    //           {
    //             count: 10277,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4486,
    //         positionX: '40.3%',
    //         positionY: '46%',
    //         title: 'Block K',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 4960,
    //             direction: 'L',
    //           },
    //           {
    //             count: 4529,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4484,
    //         positionX: '57.3%',
    //         positionY: '32.3%',
    //         title: 'Block J',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 11061,
    //             direction: 'L',
    //           },
    //           {
    //             count: 12970,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4693,
    //         positionX: '36.3%',
    //         positionY: '34%',
    //         title: 'Block I',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 14695,
    //             direction: 'L',
    //           },
    //           {
    //             count: 18639,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4476,
    //         positionX: '10.3%',
    //         positionY: '39%',
    //         title: 'Block H',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 8265,
    //             direction: 'L',
    //           },
    //           {
    //             count: 7718,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4472,
    //         positionX: '67.3%',
    //         positionY: '25%',
    //         title: 'Block G',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 11289,
    //             direction: 'L',
    //           },
    //           {
    //             count: 10230,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4470,
    //         positionX: '60.3%',
    //         positionY: '16.9%',
    //         title: 'Block F',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 9841,
    //             direction: 'L',
    //           },
    //           {
    //             count: 6053,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4468,
    //         positionX: '81.3%',
    //         positionY: '5%',
    //         title: 'Block E',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 15710,
    //             direction: 'L',
    //           },
    //           {
    //             count: 14372,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4464,
    //         positionX: '40.3%',
    //         positionY: '12.5%',
    //         title: 'Block D',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 11501,
    //             direction: 'L',
    //           },
    //           {
    //             count: 8929,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4462,
    //         positionX: '63.3%',
    //         positionY: '0%',
    //         title: 'Block C',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 31854,
    //             direction: 'L',
    //           },
    //           {
    //             count: 26563,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4458,
    //         positionX: '36.3%',
    //         positionY: '1%',
    //         title: 'Block B',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 20606,
    //             direction: 'L',
    //           },
    //           {
    //             count: 24013,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4454,
    //         positionX: '16%',
    //         positionY: '1%',
    //         title: 'Block A',
    //         titleBgColor: '#ff99fd',
    //       },
    //     ],
    //   },
    //   durationB: {
    //     image_src: '/images/mollImage/16/corridor.png',
    //     image_witdh: '570px',
    //     image_height: '1188px',
    //     arrowData: [
    //       {
    //         color: '#51fab1',
    //         id: '4514 L←',
    //         positionX: '69.9%',
    //         positionY: '76.5%',
    //         rotate: '135deg',
    //       },
    //       {
    //         color: '#6cf689',
    //         id: '4464 L←',
    //         positionX: '54.3%',
    //         positionY: '7.5%',
    //         rotate: '30deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4472 L←',
    //         positionX: '79.6%',
    //         positionY: '20.9%',
    //         rotate: '55deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4468 L←',
    //         positionX: '77.3%',
    //         positionY: '7.7%',
    //         rotate: '50deg',
    //       },
    //       {
    //         color: '#2be0d5',
    //         id: '4470 L←',
    //         positionX: '70.0%',
    //         positionY: '13%',
    //         rotate: '45deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4510 L←',
    //         positionX: '54.3%',
    //         positionY: '75.3%',
    //         rotate: '30deg',
    //       },
    //       {
    //         color: '#2be0d5',
    //         id: '4462 L←',
    //         positionX: '58.3%',
    //         positionY: '3.2%',
    //         rotate: '10deg',
    //       },
    //       {
    //         color: '#cee532',
    //         id: '4454 L←',
    //         positionX: '25.3%',
    //         positionY: '5.6%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#e6321b',
    //         id: '4458 L←',
    //         positionX: '36.8%',
    //         positionY: '6%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#0099ff',
    //         id: '4484 L←',
    //         positionX: '61.3%',
    //         positionY: '36.4%',
    //         rotate: '15deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4486 L←',
    //         positionX: '56.7%',
    //         positionY: '41.5%',
    //         rotate: '20deg',
    //       },
    //       {
    //         color: '#1d2ce7',
    //         id: '4508 L←',
    //         positionX: '58.5%',
    //         positionY: '70.7%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4518 L←',
    //         positionX: '69%',
    //         positionY: '81.3%',
    //         rotate: '40deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4520 L←',
    //         positionX: '78%',
    //         positionY: '86.3%',
    //         rotate: '80deg',
    //       },
    //       {
    //         color: '#6cf689',
    //         id: '4476 L←',
    //         positionX: '27.9%',
    //         positionY: '39.2%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#2be0d5',
    //         id: '4693 L←',
    //         positionX: '39.0%',
    //         positionY: '39.2%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#0099ff',
    //         id: '4490 L←',
    //         positionX: '80.5%',
    //         positionY: '43.3%',
    //         rotate: '70deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4492 L←',
    //         positionX: '70.7%',
    //         positionY: '46.9%',
    //         rotate: '50deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4496 L←',
    //         positionX: '78.2%',
    //         positionY: '52.9%',
    //         rotate: '70deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4500 L←',
    //         positionX: '23.5%',
    //         positionY: '73.2%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4504 L←',
    //         positionX: '35.5%',
    //         positionY: '73.4%',
    //         rotate: '0deg',
    //       },
    //       {
    //         color: '#1d2ce7',
    //         id: '4701 L←',
    //         positionX: '72.9%',
    //         positionY: '73.8%',
    //         rotate: '135deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4514 →R',
    //         positionX: '67.3%',
    //         positionY: '77.8%',
    //         rotate: '315deg',
    //       },
    //       {
    //         color: '#6cf689',
    //         id: '4464 →R',
    //         positionX: '58.0%',
    //         positionY: '8.5%',
    //         rotate: '210deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4472 →R',
    //         positionX: '81.6%',
    //         positionY: '22.6%',
    //         rotate: '235deg',
    //       },
    //       {
    //         color: '#0099ff',
    //         id: '4468 →R',
    //         positionX: '79.0%',
    //         positionY: '9.2%',
    //         rotate: '230deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4470 →R',
    //         positionX: '73%',
    //         positionY: '14.5%',
    //         rotate: '225deg',
    //       },
    //       {
    //         color: '#95ff7b',
    //         id: '4510 →R',
    //         positionX: '57.8%',
    //         positionY: '76.3%',
    //         rotate: '210deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4462 →R',
    //         positionX: '63.0%',
    //         positionY: '3.5%',
    //         rotate: '190deg',
    //       },
    //       {
    //         color: '#f0a605',
    //         id: '4454 →R',
    //         positionX: '29%',
    //         positionY: '5.6%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#f58c04',
    //         id: '4458 →R',
    //         positionX: '41%',
    //         positionY: '6%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#066ff7',
    //         id: '4484 →R',
    //         positionX: '65.6%',
    //         positionY: '37%',
    //         rotate: '195deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4486 →R',
    //         positionX: '59.8%',
    //         positionY: '42.1%',
    //         rotate: '200deg',
    //       },
    //       {
    //         color: '#0a50ff',
    //         id: '4508 →R',
    //         positionX: '62.9%',
    //         positionY: '70.7%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4518 →R',
    //         positionX: '71.5%',
    //         positionY: '82.4%',
    //         rotate: '220deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4520 →R',
    //         positionX: '78.6%',
    //         positionY: '87.9%',
    //         rotate: '260deg',
    //       },
    //       {
    //         color: '#95ff7b',
    //         id: '4476 →R',
    //         positionX: '31.9%',
    //         positionY: '39.2%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4693 →R',
    //         positionX: '43.6%',
    //         positionY: '39.2%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#066ff7',
    //         id: '4490 →R',
    //         positionX: '81.5%',
    //         positionY: '44.9%',
    //         rotate: '250deg',
    //       },
    //       {
    //         color: '#15cfde',
    //         id: '4492 →R',
    //         positionX: '73.4%',
    //         positionY: '48.5%',
    //         rotate: '230deg',
    //       },
    //       {
    //         color: '#0ab5f7',
    //         id: '4496 →R',
    //         positionX: '79.3%',
    //         positionY: '54.5%',
    //         rotate: '250deg',
    //       },
    //       {
    //         color: '#51fab1',
    //         id: '4500 →R',
    //         positionX: '27.9%',
    //         positionY: '73.2%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#95ff7b',
    //         id: '4504 →R',
    //         positionX: '39.9%',
    //         positionY: '73.4%',
    //         rotate: '180deg',
    //       },
    //       {
    //         color: '#1d2ce7',
    //         id: '4701 →R',
    //         positionX: '70.5%',
    //         positionY: '74.9%',
    //         rotate: '315deg',
    //       },
    //     ],
    //     blockData: [
    //       {
    //         blockInfo: [
    //           {
    //             count: 0,
    //             direction: 'D',
    //           },
    //           {
    //             count: 0,
    //             direction: 'U',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4701,
    //         positionX: '73%',
    //         positionY: '70%',
    //         title: 'Block FC',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 9915,
    //             direction: 'L',
    //           },
    //           {
    //             count: 9858,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4520,
    //         positionX: '66%',
    //         positionY: '91.5%',
    //         title: 'Block U',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 10280,
    //             direction: 'L',
    //           },
    //           {
    //             count: 12422,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4518,
    //         positionX: '76%',
    //         positionY: '81%',
    //         title: 'Block T',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 12603,
    //             direction: 'D',
    //           },
    //           {
    //             count: 12193,
    //             direction: 'U',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4514,
    //         positionX: '50.6%',
    //         positionY: '85.3%',
    //         title: 'Block S',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 14027,
    //             direction: 'L',
    //           },
    //           {
    //             count: 17996,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4510,
    //         positionX: '40%',
    //         positionY: '78.9%',
    //         title: 'Block R',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 654,
    //             direction: 'L',
    //           },
    //           {
    //             count: 1075,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4508,
    //         positionX: '56.5%',
    //         positionY: '65%',
    //         title: 'Block Q',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 13375,
    //             direction: 'L',
    //           },
    //           {
    //             count: 18251,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4504,
    //         positionX: '33.5%',
    //         positionY: '69.4%',
    //         title: 'Block P',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 10278,
    //             direction: 'L',
    //           },
    //           {
    //             count: 12974,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4500,
    //         positionX: '20.3%',
    //         positionY: '77%',
    //         title: 'Block O',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 7937,
    //             direction: 'L',
    //           },
    //           {
    //             count: 8323,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4496,
    //         positionX: '67.3%',
    //         positionY: '57%',
    //         title: 'Block N',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 7415,
    //             direction: 'L',
    //           },
    //           {
    //             count: 9310,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4492,
    //         positionX: '59.3%',
    //         positionY: '51.3%',
    //         title: 'Block M',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 5651,
    //             direction: 'L',
    //           },
    //           {
    //             count: 4515,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4490,
    //         positionX: '82%',
    //         positionY: '42%',
    //         title: 'Block L',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 9641,
    //             direction: 'L',
    //           },
    //           {
    //             count: 10277,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4486,
    //         positionX: '40.3%',
    //         positionY: '46%',
    //         title: 'Block K',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 4960,
    //             direction: 'L',
    //           },
    //           {
    //             count: 4529,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4484,
    //         positionX: '57.3%',
    //         positionY: '32.3%',
    //         title: 'Block J',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 11061,
    //             direction: 'L',
    //           },
    //           {
    //             count: 12970,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4693,
    //         positionX: '36.3%',
    //         positionY: '34%',
    //         title: 'Block I',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 14695,
    //             direction: 'L',
    //           },
    //           {
    //             count: 18639,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4476,
    //         positionX: '10.3%',
    //         positionY: '39%',
    //         title: 'Block H',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 8265,
    //             direction: 'L',
    //           },
    //           {
    //             count: 7718,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4472,
    //         positionX: '67.3%',
    //         positionY: '25%',
    //         title: 'Block G',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 11289,
    //             direction: 'L',
    //           },
    //           {
    //             count: 10230,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4470,
    //         positionX: '60.3%',
    //         positionY: '16.9%',
    //         title: 'Block F',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 9841,
    //             direction: 'L',
    //           },
    //           {
    //             count: 6053,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4468,
    //         positionX: '81.3%',
    //         positionY: '5%',
    //         title: 'Block E',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 15710,
    //             direction: 'L',
    //           },
    //           {
    //             count: 14372,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4464,
    //         positionX: '40.3%',
    //         positionY: '12.5%',
    //         title: 'Block D',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 11501,
    //             direction: 'L',
    //           },
    //           {
    //             count: 8929,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4462,
    //         positionX: '63.3%',
    //         positionY: '0%',
    //         title: 'Block C',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 31854,
    //             direction: 'L',
    //           },
    //           {
    //             count: 26563,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4458,
    //         positionX: '36.3%',
    //         positionY: '1%',
    //         title: 'Block B',
    //         titleBgColor: '#ff99fd',
    //       },
    //       {
    //         blockInfo: [
    //           {
    //             count: 20606,
    //             direction: 'L',
    //           },
    //           {
    //             count: 24013,
    //             direction: 'R',
    //           },
    //         ],
    //         borderColor: '#ffccfe',
    //         id: 4454,
    //         positionX: '16%',
    //         positionY: '1%',
    //         title: 'Block A',
    //         titleBgColor: '#ff99fd',
    //       },
    //     ],
    //   },
    // }

    // const initDateRange = {
    //   startDate: startDateString,
    //   endDate: endDateString,
    // }

    const durationAText = ref()
    const durationBText = ref()

    return {
      durationAText,
      durationBText,
      userData,
      isEmpty,
      loading: false,
      icons: {
        mdiArrowCollapseDown,
      },
    }
  },
}
</script>
